<template>
  <div class="content_yd">
    <div class="content_wrap_yd">
      <div class="content_crumbs_yd">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class=" content_content_yd" v-for="i in NewsData.value" :key="i.id">
        <div class="content_content_title_yd">
          {{ i.title }}
        </div>

        <div class="content_content_crumbs_yd">
          <div class="content_content_crumbs_time_yd">
            <img src="./../pic/timer.png" alt="">
            发布时间：{{i.updateTime}}
          </div>
        </div>

        <div class="content_content_item_wrap_yd">
          <div class="content_content_item_yd">
            <p v-html="i.content"></p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mainStore } from "@/store";
import { computed } from "vue";
import { storeToRefs } from "pinia";
export default {
  filters: {
    ellipsis(value) {
      value = value.replace(/<\/?.+?>/g, "");
      value = value.replace(/ /g, " ");
      value = value.replace(/.*?\}/g, " ");
      value = value.replace(/.*?\}/g, "");
      return value;
    },
  },
  setup() {
    const store = mainStore();
    const NewsData = computed(() => storeToRefs(store).YdTender);
    return {
      store,
      NewsData
    };
  },
};

</script>

<style scoped>
@import url("./conMes.css");
</style>

